import React from 'react';

export default function NotFound() {
  return (
    <div>
      <h1 className='page-not-found'>
        Page not found
      </h1>
    </div>
  );
}
